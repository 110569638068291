import React from 'react';
import { Button } from '@getvim/atomic-ui';

interface RequestOtpButtonProps {
  onRequestOtp(): void;
}

export const RequestOtpButton = ({ onRequestOtp }: RequestOtpButtonProps) => {
  const onClick = () => {
    onRequestOtp();
  };

  return (
    <div className="text-center margin-top-20 margin-bottom-20">
      <Button className="btn-blue" type="submit" onClick={onClick}>
        Request one time password
      </Button>
    </div>
  );
};
