import React, { createContext, useContext, useState, PropsWithChildren } from 'react';
import { LoginWidgetModificationPayload } from '../../logic/messaging';
import { WidgetPosition } from './utils/positioningUtils';

export interface InlineWidgetConfig {
  modification: LoginWidgetModificationPayload;
  direction: WidgetPosition;
}
export interface IInlineWidgetContext {
  context: InlineWidgetConfig;
  updateContext: (context: InlineWidgetConfig) => void;
}

const INLINE_WIDGET_DEFAULT_HEIGHT = '110px';

export const INLINE_WIDGET_MODIFICATION = {
  size: { width: '60px', height: INLINE_WIDGET_DEFAULT_HEIGHT },
  alignment: { top: '110', right: '0' },
};

const InlineWidgetContext = createContext<IInlineWidgetContext>({
  context: { modification: INLINE_WIDGET_MODIFICATION, direction: WidgetPosition.High },
  updateContext: () => {},
});

export function InlineWidgetContextProvider({ children }: PropsWithChildren<unknown>) {
  const [context, updateContext] = useState<InlineWidgetConfig>({
    modification: INLINE_WIDGET_MODIFICATION,
    direction: WidgetPosition.High,
  });

  return (
    <InlineWidgetContext.Provider
      value={{
        context,
        updateContext,
      }}
    >
      {children}
    </InlineWidgetContext.Provider>
  );
}

export function useInlineWidgetContext() {
  return useContext(InlineWidgetContext);
}
