import React, { FC } from 'react';
import { Row } from 'react-bootstrap';
import { Rule, rules as mainRules } from './rules-logic';

export interface PasswordRulesBoxProps {
  password: string;
  rules?: Rule[];
}

interface PasswordRuleProps {
  rule: Rule;
  password: string;
}

const PasswordRule: FC<PasswordRuleProps> = ({ rule, password }) => {
  const isValid = rule.test({ password });
  const { message, subRules } = rule;

  return (
    <>
      <Row className="password-rule">
        <div className="rule-circle">{isValid ? <i className="icon-check" /> : ''}</div>
        <div className="rule-message">
          {isValid ? <strong>{message}</strong> : <span>{message}</span>}
        </div>
      </Row>
      {subRules ? (
        <div className="sub-password-rules-box">
          <PasswordRulesBox rules={subRules} password={password} />
        </div>
      ) : undefined}
    </>
  );
};

export const PasswordRulesBox: FC<PasswordRulesBoxProps> = ({ password, rules = mainRules }) => {
  return (
    <div className="password-rules-box">
      {rules.map((rule) => (
        <PasswordRule rule={rule} password={password} />
      ))}
    </div>
  );
};
