import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';

const gqlClient = new ApolloClient({
  link: createHttpLink({ uri: '/api/graphql' }),
  cache: new InMemoryCache({
    resultCaching: false,
  }),
});

export default gqlClient;
