import React from 'react';
import { LoginEvents, SeamlessDeniedState } from '../types';
import { LoginFlowManager } from './LoginFlowManager';
import { LoginErrorScreen } from './LoginErrorScreen';

export const LoginSwitch = ({
  ehrCredentials,
  credentialsMetadata,
  reLoginConfig,
  organizationId,
  contactDetails,
  isSeamlessLoginFailed,
  wasSeamlessLoginShownAndDenied,
  goBackToSeamlessLogin,
}: LoginEvents & SeamlessDeniedState) => {
  if (reLoginConfig)
    return (
      <LoginErrorScreen
        message={reLoginConfig.message}
        title={reLoginConfig.title}
        showLockImage={reLoginConfig.showLockImage}
      />
    );

  if (
    ehrCredentials &&
    (ehrCredentials.password ||
      credentialsMetadata?.shouldShowCredentialsWithError ||
      credentialsMetadata?.isEhrPasswordOptional)
  )
    return (
      <LoginFlowManager
        ehrCredentials={ehrCredentials}
        credentialsMetadata={credentialsMetadata}
        organizationId={organizationId}
        contactDetails={contactDetails}
        isSeamlessLoginFailed={isSeamlessLoginFailed}
        wasSeamlessLoginShownAndDenied={wasSeamlessLoginShownAndDenied}
        goBackToSeamlessLogin={goBackToSeamlessLogin}
      />
    );
};
