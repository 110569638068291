import React from 'react';
import { ProgressBar, Button } from '@getvim/atomic-ui';
import './index.less';
import hourglassSvg from '../../../resources/images/hourglass.svg';

export enum MovementDirection {
  Down,
  Up,
}

export interface InlineLoginProgressWidgetProps {
  onWidgetClick: () => void;
  moveWidget: (direction: MovementDirection) => void;
  loginPercentage: number;
  topButtonDisabled?: boolean;
  bottomButtonDisabled?: boolean;
}

export const InlineLoginProgressWidget: React.FC<InlineLoginProgressWidgetProps> = ({
  onWidgetClick,
  loginPercentage,
  topButtonDisabled,
  bottomButtonDisabled,
  moveWidget,
}) => (
  <div className="inline-login-progress-widget">
    <Button
      className={`icon-chevron-up chevron-button up ${topButtonDisabled ? 'disabled' : ''}`}
      buttonType="link"
      onClick={() => moveWidget(MovementDirection.Up)}
    />
    <div
      className="inline-hourglass"
      onClick={onWidgetClick}
      onKeyDown={onWidgetClick}
      role="button"
      tabIndex={0}
    >
      <img src={hourglassSvg} alt="hourglass" className="hourglass-img-inline" />
      <ProgressBar
        className="inline-login-progress-bar"
        width="small"
        progressBarStyle="tiny"
        value={loginPercentage}
      />
    </div>
    <Button
      className={`icon-chevron-down chevron-button down ${bottomButtonDisabled ? 'disabled' : ''}`}
      buttonType="link"
      onClick={() => moveWidget(MovementDirection.Down)}
    />
  </div>
);
