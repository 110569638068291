import { Loader } from '@getvim/atomic-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { Organisms } from '../../atomic';
import { analyticsClient, VimConnectSeamlessAnalyticsEventTypes } from '../../logic/analytics';
import { close, sendSeamlessApproved } from '../../logic/messaging';
import { SeamlessLoginWidget, SeamlessLoginWidgetProps } from './SeamlessLoginWidget';

export type SeamlessLoginProps = SeamlessLoginWidgetProps & {
  onSeamlessDenied: () => void;
  autoApprove?: boolean;
};

export const SeamlessLogin: React.FC<SeamlessLoginProps> = ({
  onSeamlessDenied,
  autoApprove,
  ...props
}) => {
  const [approved, setIsApproved] = useState(false);
  const seamlessApproved = useCallback(() => {
    setIsApproved(true);
    sendSeamlessApproved();
  }, [setIsApproved]);

  const learnMoreVisibilityChanged = useCallback((visibility) => {
    analyticsClient.track(
      VimConnectSeamlessAnalyticsEventTypes.VimConnectSeamlessConsentLearnMoreVisibility,
      {
        learn_more_visible: visibility,
      },
    );
  }, []);

  const onClose = useCallback(() => {
    close(true);
  }, []);

  useEffect(() => {
    if (autoApprove) {
      seamlessApproved();
    } else {
      analyticsClient.track(
        VimConnectSeamlessAnalyticsEventTypes.VimConnectSeamlessConsentDisplayed,
        {},
      );
    }
  }, [autoApprove, seamlessApproved]);

  const autoApproveInProgress = autoApprove && !approved;

  if (autoApproveInProgress) {
    return <Loader />;
  }

  return approved ? (
    <SeamlessLoginWidget {...props} />
  ) : (
    <Organisms.ApprovalWidget
      onApproved={seamlessApproved}
      onDenied={onSeamlessDenied}
      onLearnMoreVisibilityChanged={learnMoreVisibilityChanged}
      onClose={onClose}
      showDifferentMethods={!!(props.ehrCredentials && props.ehrCredentials.password)}
    />
  );
};
