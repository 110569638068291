import { AnalyticsClient } from '@getvim/utils-vim-connect-communication';
import { WidgetPosition } from '../../components/seamless-login/utils/positioningUtils';
import { LoginWidgetModificationPayload } from '../messaging';
import { ContactDetailType } from '../../types';

export enum ElementInteraction {
  Expanded = 'Expanded',
  Collapse = 'Collapse',
}

export enum VimConnectSeamlessAnalyticsEventTypes {
  VimConnectSeamlessConsentDisplayed = 'vim_connect_seamless_consent_displayed',
  VimConnectSeamlessConsentLearnMoreVisibility = 'vim_connect_seamless_consent_learn_more_visibility',
  VimConnectSeamlessConsentDenied = 'vim_connect_seamless_consent_denied',
  VimConnectWentBackToSeamless = 'vim_connect_went_back_to_seamless',
  VimConnectSeamlessPositionChange = 'vim_connect_seamless_position_change',
  VimConnectWidgetInteraction = 'vim_connect_seamless_widget_interaction',
}

export enum VimConnectOtpLoginAnalyticsEventTypes {
  VimConnectOtpRequestCodeButtonClicked = 'vim_connect_otp_request_code_button_clicked',
  VimConnectOtpSubmitCodeButtonClicked = 'vim_connect_otp_submit_code_button_clicked',
  VimConnectOtpSendCodeButtonClicked = 'vim_connect_otp_send_code_button_clicked',
  VimConnectLoginDisplayed = 'vim_connect_login_displayed',
  VimConnectOtpErrorPageDisplayed = 'vim_connect_otp_error_page_displayed',
  VimConnectInitialSuccessLogin = 'vim_connect_initial_successful_login',
}

export enum VimConnectCredentialsLoginAnalyticsEventTypes {
  VimConnectCredentialsForgotPasswordButtonClicked = 'vim_connect_credentials_forgot_password_button_clicked',
  vimConnectCredentialsSendResetPasswordEmailButtonClicked = 'vim_connect_credentials_send_reset_password_email_button_clicked',
  VimConnectCredentialsLoginButtonClicked = 'vim_connect_credentials_login_button_clicked',
}

export enum LoginMethods {
  credentials = 'credentials',
  otp = 'otp',
  seamless = 'seamless',
}

type VimConnectUiAnalyticsEvents = {
  [VimConnectSeamlessAnalyticsEventTypes.VimConnectSeamlessPositionChange]: {
    direction: WidgetPosition;
    old_position: LoginWidgetModificationPayload;
    new_position: LoginWidgetModificationPayload;
  };
  [VimConnectSeamlessAnalyticsEventTypes.VimConnectWidgetInteraction]: {
    interaction: ElementInteraction;
  };
  [VimConnectSeamlessAnalyticsEventTypes.VimConnectSeamlessConsentDisplayed]: Record<
    string,
    string
  >;
  [VimConnectSeamlessAnalyticsEventTypes.VimConnectSeamlessConsentLearnMoreVisibility]: {
    learn_more_visible: boolean;
  };
  [VimConnectSeamlessAnalyticsEventTypes.VimConnectSeamlessConsentDenied]: Record<string, unknown>;
  [VimConnectSeamlessAnalyticsEventTypes.VimConnectWentBackToSeamless]: Record<string, unknown>;
  [VimConnectOtpLoginAnalyticsEventTypes.VimConnectOtpSendCodeButtonClicked]: {
    method: ContactDetailType;
    is_success: boolean;
    attempt_number: number;
  };
  [VimConnectOtpLoginAnalyticsEventTypes.VimConnectOtpRequestCodeButtonClicked]: {
    attempt_number: number;
  };
  [VimConnectCredentialsLoginAnalyticsEventTypes.VimConnectCredentialsForgotPasswordButtonClicked]: {
    user_has_email: boolean;
  };
  [VimConnectCredentialsLoginAnalyticsEventTypes.vimConnectCredentialsSendResetPasswordEmailButtonClicked]: {};
  [VimConnectOtpLoginAnalyticsEventTypes.VimConnectLoginDisplayed]: {
    is_otp_displayed: boolean;
  };
  [VimConnectOtpLoginAnalyticsEventTypes.VimConnectOtpSubmitCodeButtonClicked]: {
    method: ContactDetailType;
    is_success: boolean;
    attempt_number: number;
  };
  [VimConnectOtpLoginAnalyticsEventTypes.VimConnectOtpErrorPageDisplayed]: {
    reason: string;
  };
  [VimConnectOtpLoginAnalyticsEventTypes.VimConnectInitialSuccessLogin]: {
    login_type: LoginMethods;
  };
  [VimConnectCredentialsLoginAnalyticsEventTypes.VimConnectCredentialsLoginButtonClicked]: {
    is_success: boolean;
    attempt_number: number;
  };
};

export const analyticsClient = new AnalyticsClient<VimConnectUiAnalyticsEvents>();
analyticsClient.setNewActiveSessionId();
