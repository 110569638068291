import React, { FC } from 'react';
import { BrandedH1, Text, ColorNameEnum, SizeEnum } from '@getvim/atomic-ui';
import emailSentImage from '../../resources/images/password-reset-sent-image.png';

export const EmailSentMessage: FC = () => (
  <>
    <div className="text-center margin-bottom-30">
      <img src={emailSentImage} className="reset-password-img" alt="" />
    </div>
    <BrandedH1 margin="none" text="Reset Password" />
    <Text
      className="margin-top"
      colorName={ColorNameEnum.theme}
      size={SizeEnum['14px']}
      text="We sent you an email. If you do not receive it within a few seconds, please contact your IT manager or send us an email to provider@getvim.com."
    />
  </>
);
