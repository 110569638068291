import React from 'react';
import lockImage from '../resources/images/password-reset-sent-image.png';
import errorImage from '../resources/images/error-image.png';

interface LoginErrorScreenProps {
  message: string;
  title?: string;
  showLockImage?: boolean;
}

export const LoginErrorScreen: React.FC<LoginErrorScreenProps> = ({
  message,
  title,
  showLockImage,
}) => (
  <div className="padding-h-20 text-center padding-top-40">
    <img alt="error" src={showLockImage ? lockImage : errorImage} className="error-img" />
    <h1>{title ?? 'Oops, an error occurred.'}</h1>
    <div className="subtitle text-14">{message}</div>
  </div>
);
