import { useEffect, useState } from 'react';
import { registerWidget } from '@getvim/utils-vim-connect-communication';
import {
  ContactDetail,
  CredentialsEventPayload,
  CredentialsMetadata,
  EhrCredentials,
  EhrUserLinkingState,
  Event,
  LoginEvents,
  ReLoginConfig,
  SeamlessLoginConfig,
} from '../types';

export const useConnectLoginEvents = (): LoginEvents => {
  const [ehrCredentials, setEhrCredentials] = useState<EhrCredentials | undefined>();
  const [credentialsMetadata, setCredentialsMetadata] = useState<CredentialsMetadata | undefined>();
  const [reLoginConfig, setReLoginConfig] = useState<ReLoginConfig>();
  const [organizationId, setOrganizationId] = useState<number>(0);
  const [contactDetails, setContactDetails] = useState<ContactDetail[]>([]);
  const [seamlessLoginConfiguration, setSeamlessLoginConfiguration] = useState<
    SeamlessLoginConfig | undefined
  >();

  useEffect(() => {
    registerWidget((data) => {
      const { event } = data;
      switch (event) {
        case Event.INIT_ORGANIZATION_ID:
          setOrganizationId(data.payload.organizationId);
          break;
        case Event.CREDENTIALS: {
          const payload = data.payload as CredentialsEventPayload;

          const shouldShowCredentialsWithError =
            payload.credentialsMetadata.canUseCredentialsLoginWithoutEhrPasswordFF &&
            payload.credentialsMetadata.ehrUserLinkingState === EhrUserLinkingState.Unlinked;

          setCredentialsMetadata({
            ...payload.credentialsMetadata,
            shouldShowCredentialsWithError,
          });
          setEhrCredentials(payload.ehrCredentials);
          break;
        }
        case Event.SEAMLESS_LOGIN_INIT:
          setSeamlessLoginConfiguration({
            widgetTimeout: data.payload.widgetTimeout,
            shouldUseSeamlessLogin: true,
            autoApprove: data.payload.autoApprove,
          });
          setEhrCredentials(data.payload.credentials);
          break;
        case Event.DISPLAY_RE_LOGIN_SCREEN:
          setReLoginConfig(data.payload);
          break;
        case Event.SET_OTP_CONTACT_DETAILS:
          setContactDetails(data.payload);
          break;
        case Event.SEAMLESS_LOGIN_FAILED:
          setSeamlessLoginConfiguration({
            ...seamlessLoginConfiguration,
            shouldUseSeamlessLogin: false,
            isSeamlessLoginFailed: true,
          });
          break;
        case Event.SEAMLESS_LOGIN_SUCCESS:
          setSeamlessLoginConfiguration({
            ...seamlessLoginConfiguration,
            shouldUseSeamlessLogin: true,
            seamlessCredentials: data.payload,
            isSeamlessSucceeded: true,
          });
          break;

        default:
          break;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ehrCredentials,
    credentialsMetadata,
    reLoginConfig,
    organizationId,
    contactDetails,
    seamlessLoginConfiguration,
  };
};
