import React, { FC } from 'react';
import { Button, Text, ColorNameEnum, SizeEnum } from '@getvim/atomic-ui';

interface PasswordResetLinkProps {
  onClick: () => void;
}

export const PasswordResetLink: FC<PasswordResetLinkProps> = ({ onClick }) => (
  <div>
    <Button className="forgot-password-btn" buttonType="link" onClick={onClick}>
      <Text colorName={ColorNameEnum.theme} size={SizeEnum['14px']} text="Forgot password?" />
    </Button>
  </div>
);
