import { AlignEnum, ColorNameEnum, SizeEnum, Text, WeightEnum } from '@getvim/atomic-ui';
import React from 'react';
import { BackButton } from '../../atoms/back-button';
import './index.less';

export interface SeamlessConsentInfoProps {
  goBack: () => void;
}

const SubHeader = ({ children }: React.PropsWithChildren<unknown>) => (
  <Text
    className="consent-sub-header"
    size={SizeEnum['12px']}
    weight={WeightEnum.semibold}
    align={AlignEnum.left}
    colorName={ColorNameEnum.theme}
  >
    {children}
  </Text>
);

const Paragraph = ({ children }: React.PropsWithChildren<unknown>) => (
  <Text className="consent-paragraph" size={SizeEnum['12px']} align={AlignEnum.left}>
    {children}
  </Text>
);

export const SeamlessConsentInfo: React.FC<SeamlessConsentInfoProps> = ({ goBack }) => {
  return (
    <div className="seamless-consent-info">
      <BackButton onBack={goBack} />
      <div className="consent-info-body">
        <Text
          className="consent-title"
          size={SizeEnum['22px']}
          weight={WeightEnum.semibold}
          align={AlignEnum.left}
          colorName={ColorNameEnum.theme}
        >
          Automated verification process FAQ
        </Text>
        <SubHeader>What is the automated verification process?</SubHeader>
        <Paragraph>
          When you set-up your Vim account for the first time, or when you change your password to
          your EHR system, Vim needs to verify your identity. Each time you login into your
          Electronic Health Record System (<b>EHR</b>) the EHR issues a unique certificate that
          matches with your username. Vim will gain access to this certificate in order to verify
          your user name.
        </Paragraph>
        <SubHeader>What will Vim be doing with the EHR certificate?</SubHeader>
        <Paragraph>
          Your EHR login certificate will be examined only once when you attempt to login into the
          EHR for the first time or when you are requested to change your password. During the
          automated verification process Vim will use the EHR certificate to verify your identity.
          Once the automated verification process is completed your EHR certificate will be deleted
          from Vim. Vim will not store or save your EHR certificate in any way. Vim does not, and
          will not, share your EHR certificate with any third party under any circumstance.
        </Paragraph>
        <SubHeader>Can I verify myself with Vim in another way?</SubHeader>
        <Paragraph>
          Yes you can. You can do that by choosing a different method of verification. You can
          always verify your identity by creating your own password for the Vim platform.
        </Paragraph>
        <SubHeader>
          What am I authorizing Vim to do and why is this authorization required?
        </SubHeader>
        <Paragraph>
          To work seamlessly within your EHR Vim needs to access your EHR certificate, your
          authorization is required for this. Please note that your authorization does not give us
          the permission to do anything else on your behalf in the EHR system.
        </Paragraph>
        <SubHeader>Can I refuse to be verified in this method?</SubHeader>
        <Paragraph>
          Yes you can, however, Vim will need to verify your identity in another method as explained
          above.
        </Paragraph>
        <SubHeader>Can I withdraw my authorization?</SubHeader>
        <Paragraph>
          The automated verification process happens on a one time basis, and Vim does not store or
          save your EHR certificate. Therefore, once authorization was granted it cannot be
          withdrawn. However, you can choose to use a different verification process in future
          verification requests.
        </Paragraph>
        <SubHeader>
          Does the BAA I have with Vim apply to the automated verification process?
        </SubHeader>
        <Paragraph>
          No, as your EHR login credentials are not considered protected health information (PHI).
          Vim’s privacy policy, and the obligations contained in this FAQ apply to the automated
          verification process.
        </Paragraph>
      </div>
    </div>
  );
};
