import React from 'react';
import SpaceshipSbg from '../../../resources/images/spaceship.svg';

export const UserWithoutEmail = () => {
  return (
    <div className="user-without-email text-center">
      <div className="headline">
        <img alt="error" src={SpaceshipSbg} className="error-image" />
        <h1 className="problem-text">We can&#39;t reset your password</h1>
      </div>
      <div className="help-text-footer">
        We don&#39;t have your email address. We need it in order to reset your password.
        <br />
        please contact your IT manager or send us an email to provider@getvim.com
      </div>
    </div>
  );
};
