export enum LoginStep {
  CREDENTIALS_FORM = 'CREDENTIALS_FORM',
  SUCCESS_LOGIN = 'SUCCESS_LOGIN',
  FAILURE = 'FAILURE',
  OTP_FAILURE = 'OTP_FAILURE',
  PASSWORD_RESET_REQUEST_FORM = 'PASSWORD_RESET_REQUEST_FORM',
  EXPIRED_PASSWORD_CHANGE = 'EXPIRED_PASSWORD_CHANGE',
  OTP_LOGIN = 'OTP_LOGIN',
  REQUEST_OTP = 'REQUEST_OTP',
  CHECK_YOUR_EMAIL = 'CHECK_YOUR_EMAIL',
  CHECK_YOUR_PHONE = 'CHECK_YOUR_PHONE',
}

export type LoginSteps = typeof LoginStep;
