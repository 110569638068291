import React, { useState, useEffect } from 'react';
import { useLogin } from '../../hooks';
import { Loader } from '../loader';
import {
  EhrCredentials,
  OnFailureCallback,
  OnSuccessCallback,
  SeamlessCredentials,
} from '../../types';
import { AccessToken, LoginResult } from '../../logic/types';

interface SeamlessLoginSuccessProps {
  ehrCredentials?: EhrCredentials;
  seamlessCredentials?: SeamlessCredentials;
  organizationId: number;
  onSuccess: OnSuccessCallback;
  onFailure: OnFailureCallback;
}

export const SeamlessLoginSuccess: React.FC<SeamlessLoginSuccessProps> = ({
  ehrCredentials,
  organizationId,
  seamlessCredentials,
  onSuccess,
  onFailure,
}: SeamlessLoginSuccessProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [accessToken, setAccessToken] = useState<AccessToken>();
  const { login, loading: loginLoading } = useLogin(organizationId);

  const loading = isLoading || loginLoading;

  useEffect(() => {
    async function onLogin() {
      if (!ehrCredentials) return;

      setIsLoading(true);
      try {
        const { linkedEhrUsername, ...loginEhrCredentials } = ehrCredentials;
        const loginResponse = await login({
          organizationId,
          seamlessLoginCredentials: seamlessCredentials,
          ehrCredentials: loginEhrCredentials,
        });
        setAccessToken(loginResponse);
      } catch (error) {
        // If seamless Passed, but login after failed, dont retry
        onFailure(error as Error, false);
      } finally {
        setIsLoading(false);
      }
    }
    onLogin();
  }, [ehrCredentials, login, onFailure, organizationId, seamlessCredentials]);

  useEffect(() => {
    if (!accessToken) return;

    onSuccess({ accessToken } as LoginResult);
  }, [accessToken, onSuccess]);

  if (loading)
    return (
      <div style={{ marginTop: '50px' }}>
        <Loader />
      </div>
    );

  return null;
};

SeamlessLoginSuccess.defaultProps = {
  ehrCredentials: undefined,
  seamlessCredentials: undefined,
};
