export interface Rule {
  message: string;
  test: (params: { password: string; }) => boolean;
  subRules?: Rule[];
}

const characterRules: Rule[] = [
  {
    message: 'The password must contain lower case letters(a-z)',
    test: ({ password }) => /[a-z]/.test(password),
  },
  {
    message: 'The password must contain upper case letters(A-Z)',
    test: ({ password }) => /[A-Z]/.test(password),
  },
  {
    message: 'The password must contain numbers',
    test: ({ password }) => /[0-9]/.test(password),
  },
  {
    message: 'The password must contain special character(!@#$%^&*)',
    test: ({ password }) => /[!@#$%^&*]/.test(password),
  },
];

export const rules: Rule[] = [
  {
    message: 'The password must contain at least 8 characters',
    test: ({ password }) => password.length >= 8,
  },
  {
    message: 'Must contain at least 3 out of the following rules',
    test: ({ password }) => {
      const totalCount = characterRules.reduce(
        (count, rule) => count + (rule.test({ password }) ? 1 : 0),
        0,
      );
      return totalCount >= 3;
    },
    subRules: characterRules,
  },
];
/**
 * returns whether all rules have passed.
 */
export const testRules = (password: string): boolean => {
  return rules.every((rule) => rule.test({ password }));
};
