import React from 'react';
import { Button } from '@getvim/atomic-ui';

interface BackBtnProps {
  onBack(): void;
}

export const BackButton = ({ onBack }: BackBtnProps) => (
  <Button buttonType="link" onClick={onBack} className="back-btn link-btn">
    <i className="icon-chevron-2" />
    Back
  </Button>
);
