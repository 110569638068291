import React, { useEffect, useState } from 'react';
import { Loader, Button } from '@getvim/atomic-ui';
import { Login } from '@getvim/vim-connect';
import OtpInput from 'react-otp-input';
import { useLogin } from '../../hooks';
import {
  analyticsClient,
  LoginMethods,
  VimConnectOtpLoginAnalyticsEventTypes,
} from '../../logic/analytics';
import { AccessToken } from '../../logic/types';
import LaptopSvg from '../../resources/images/laptop.svg';
import PhoneSvg from '../../resources/images/phone.svg';
import { ContactDetailType, Credentials, OtpLoginInput } from '../../types';
import { BackButton } from './back-button';
import { loginWidgetLogger } from '../../logic/logger';
import { sendUserLoginAttempt } from '../../logic/messaging';

interface EnterOtpCodeProps {
  onBack: () => void;
  destinationHint: string;
  contactDetailType: ContactDetailType;
  organizationId: number;
  ehrUser: string;
  ehrCredentials: Credentials;
  onSuccess: (args: AccessToken) => void;
  onFailure: (error?) => void;
  onResendPassword: () => void;
  otpRequestLoading: boolean;
  otpSubmitCodeAttemptCounter: number;
  setOtpSubmitCodeAttemptCounter: (arg: number) => void;
}

const otpInputStyle = {
  backgroundColor: '#DCF5F5',
  height: '50px',
  width: '40px',
  borderRadius: '4px',
  marginLeft: '10px',
  border: 'none',
};
const otpCodeLength = 6;
const maxLoginAttempts = 3;

export const EnterOtpCode = ({
  onBack,
  destinationHint,
  contactDetailType,
  organizationId,
  ehrCredentials,
  ehrUser,
  onSuccess,
  onFailure,
  onResendPassword,
  otpRequestLoading,
  otpSubmitCodeAttemptCounter,
  setOtpSubmitCodeAttemptCounter,
}: EnterOtpCodeProps) => {
  const [otpInput, setOtpInput] = useState<string>('');
  const [accessToken, setAccessToken] = useState<AccessToken>();
  const { login, loading: loginLoading } = useLogin(organizationId);

  const onLoginFailed = (attemptCounter: number, error?) => {
    if (error?.message === Login.INTERNAL_SERVER_ERROR_MESSAGE) {
      return onFailure(error);
    }
    if (attemptCounter >= maxLoginAttempts) {
      onFailure();
      return;
    }
    setOtpInput('');
    setOtpSubmitCodeAttemptCounter(attemptCounter);
  };

  const onSubmit = async (lastOtpInput?: string) => {
    const actualOtpInput = lastOtpInput || otpInput;
    if (!actualOtpInput) return;

    const otpLoginInput: OtpLoginInput = {
      ehrUser,
      contactDetailType,
      otp: actualOtpInput,
    };
    let isSuccess = true;
    const attemptCounter = otpSubmitCodeAttemptCounter + 1;

    try {
      const loginResponse = await login({
        organizationId,
        otpLoginInput,
        ehrCredentials,
      });
      setAccessToken(loginResponse);
      sendUserLoginAttempt(Login.LoginMethodType.OTP, {
        accessToken: loginResponse.accessToken,
      });
      analyticsClient.track(VimConnectOtpLoginAnalyticsEventTypes.VimConnectInitialSuccessLogin, {
        login_type: LoginMethods.otp,
      });
      loginWidgetLogger.warning('Vim Connect initial successful login', {
        login_type: LoginMethods.otp,
      });
    } catch (error: any) {
      sendUserLoginAttempt(Login.LoginMethodType.OTP, {
        errorMessage: error?.message,
        attemptNumber: attemptCounter,
      });
      isSuccess = false;
      onLoginFailed(attemptCounter, error);
    }

    analyticsClient.track(
      VimConnectOtpLoginAnalyticsEventTypes.VimConnectOtpSubmitCodeButtonClicked,
      {
        is_success: isSuccess,
        method: contactDetailType,
        attempt_number: attemptCounter,
      },
    );
  };

  const handleOtpInput = (newInput: string) => {
    setOtpInput(newInput);
    if (newInput.length === otpCodeLength) {
      onSubmit(newInput);
    }
  };

  useEffect(() => {
    if (!accessToken) return;
    onSuccess(accessToken);
  }, [accessToken, onSuccess]);

  return (
    <div>
      <BackButton onBack={onBack} />
      <div className="enter-code text-center">
        <h1 className="text-left margin-bottom-10">Check your {contactDetailType}</h1>
        {contactDetailType === ContactDetailType.email ? (
          <img src={LaptopSvg} alt="computer" />
        ) : (
          <img src={PhoneSvg} alt="phone" />
        )}
        <p className="destination-hint">
          Enter code sent to <b>{destinationHint}</b>
        </p>
        <OtpInput
          value={otpInput}
          onChange={handleOtpInput}
          numInputs={otpCodeLength}
          inputStyle={otpInputStyle}
          isInputNum
        />
        {loginLoading || otpRequestLoading ? (
          <Loader type="dots" padding="small" className="validate-loading" />
        ) : (
          <div>
            <div className="error-wrap">
              <p className="error-message">
                {otpSubmitCodeAttemptCounter > 0 && 'The code is wrong, please try again'}
              </p>
            </div>
            <div className="resend-code">
              <p>Didn’t receive the code?</p>
              <Button buttonType="link" className="link-btn" onClick={onResendPassword}>
                Ask for a new code
              </Button>
            </div>
          </div>
        )}
        <Button
          className="btn-blue"
          type="submit"
          onClick={onSubmit}
          disabled={otpInput.length !== otpCodeLength || loginLoading}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
