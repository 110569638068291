import { ContactDetailType } from '../types/contact-details';

export interface Application {
  name: string;
}

export interface LoginResult {
  accessToken: AccessToken;
}

export enum UserType {
  EhrUser = 'EHR_USER',
  VimEhrUser = 'VIM_EHR_USER',
  VimAdmin = 'VIM_ADMIN',
}

export interface AccessToken {
  accessToken: string;
  refreshToken: string;
  expiresIn?: number;
  tokenType?: 'Bearer';
}

export type SuccessResponse = {
  success: boolean;
};

// TODO: remove this after migrating to SendOtp
export interface OtpRequestInput {
  organizationId: number;
  ehrUser: string;
  contactDetailType: ContactDetailType;
}

export interface SendOtpInput {
  organizationId: number;
  ehrUser: string;
  contactDetailType: ContactDetailType;
}
