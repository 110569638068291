import { ApolloError, useMutation } from '@apollo/client';
import { initialLoginMutation } from '../logic/queries';
import {
  Credentials,
  ExpiredPasswordError,
  InternalServerError,
  OtpLoginInput,
  SeamlessCredentials,
} from '../types';
import { AccessToken } from '../logic/types';
import { Login } from '@getvim/vim-connect';
import { useCallback } from 'react';

enum ErrorType {
  EXPIRED_PASSWORD = 'EXPIRED_PASSWORD',
  INTERNAL_SERVER_ERROR = 'INTERNAL_ERROR',
}

const isPasswordExpired = (error: ApolloError): boolean => {
  const errors = error.graphQLErrors;
  return !!errors.some((e) => e.extensions?.code === ErrorType.EXPIRED_PASSWORD);
};

const isInternalServerError = (error: ApolloError): boolean => {
  const errors = error.graphQLErrors;
  return !!errors.some((e) => e.extensions?.code === ErrorType.INTERNAL_SERVER_ERROR);
};

export const useLogin = (organizationId: number) => {
  const [initialLoginMutate, { loading: initialLoginMutationLoading }] = useMutation(
    initialLoginMutation,
    { context: { uri: '/runtime/api/graphql' } },
  );

  const login = useCallback(
    async (input: {
      organizationId: number;
      vimCredentials?: Credentials;
      otpLoginInput?: OtpLoginInput;
      seamlessLoginCredentials?: SeamlessCredentials;
      ehrCredentials: Credentials;
    }) => {
      try {
        const loginResult = await initialLoginMutate({ variables: { input } });
        const accessToken: AccessToken = loginResult.data?.initialLogin;

        return accessToken;
      } catch (error) {
        if (isPasswordExpired(error as ApolloError)) {
          throw new ExpiredPasswordError('password expired');
        }

        if (isInternalServerError(error as ApolloError)) {
          throw new InternalServerError(Login.INTERNAL_SERVER_ERROR_MESSAGE);
        }
        throw error;
      }
    },
    [initialLoginMutate],
  );

  return {
    login,
    loading: initialLoginMutationLoading,
  };
};
