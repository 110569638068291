import React, { FC } from 'react';
import { useMutation } from '@apollo/client';
import { BackButton } from '../../atomic/atoms/back-button';
import { EmailSentMessage } from './email-sent-message-new';
import { UserWithoutEmail, UserWithEmail } from './email-cases';
import { ContactDetail, ContactDetailType } from '../../types';
import { sendPasswordResetLinkMutation } from '../../logic/queries';
import { UserType } from '../../logic/types';
import {
  analyticsClient,
  VimConnectCredentialsLoginAnalyticsEventTypes,
} from '../../logic/analytics';

export interface PasswordResetFormProps {
  exitForm: () => void;
  contactDetails?: ContactDetail[];
  username: string;
  organizationId: number;
}

export const PasswordResetForm: FC<PasswordResetFormProps> = ({
  exitForm,
  contactDetails,
  username,
  organizationId,
}) => {
  const [sendPasswordReset, { called }] = useMutation(sendPasswordResetLinkMutation, {
    variables: {
      input: {
        username,
        organizationId,
        contactDetailType: ContactDetailType.email,
        userType: UserType.VimEhrUser,
      },
    },
    context: { uri: '/runtime/api/graphql' },
  });

  const userHasEmail = contactDetails?.find((detail) => detail.type === ContactDetailType.email);

  const onPasswordResetSubmit = () => {
    analyticsClient.track(
      VimConnectCredentialsLoginAnalyticsEventTypes.vimConnectCredentialsSendResetPasswordEmailButtonClicked,
      {},
    );
    sendPasswordReset();
  };

  return (
    <div>
      <form name="vim-ehr-login" autoComplete="off">
        <BackButton onBack={exitForm} />
        <div className="login-form-row">
          {called ? (
            <EmailSentMessage />
          ) : (
            <>
              {userHasEmail ? (
                <UserWithEmail
                  email={userHasEmail.hint}
                  username={username}
                  onPasswordResetSubmit={onPasswordResetSubmit}
                />
              ) : (
                <UserWithoutEmail />
              )}
            </>
          )}
        </div>
      </form>
    </div>
  );
};
