import React, { Dispatch, SetStateAction } from 'react';
import { BackButton } from '../../atomic/atoms/back-button';
import { LoginResult } from '../../logic/types';
import { Credentials, CredentialsMetadata } from '../../types';
import CredentialsForm from '../CredentialsForm';
import { SeamlessLoginFailed } from '../seamless-login';
import { RequestOtpButton } from './request-otp-button';

interface OtpLoginPageProps {
  ehrCredentials: Credentials;
  credentialsMetadata: CredentialsMetadata | undefined;
  ehrUsername: string;
  organizationId: number;
  onCredsSuccess(loginResult: LoginResult): void;
  onCredsFailure(error: Error): void;
  onPasswordResetRequest(): void;
  onPasswordExpired(vimCredentials: Credentials): void;
  onRequestOtp(): void;
  isSeamlessLoginFailed?: boolean;
  wasSeamlessLoginShowedAndDenied?: boolean;
  goBackToSeamlessLogin: () => void;
  credentialsLoginAttemptCounter: number;
  setCredentialsLoginAttemptCounter: Dispatch<SetStateAction<number>>;
}

export const OtpLoginPage = ({
  ehrCredentials,
  credentialsMetadata,
  ehrUsername,
  organizationId,
  onCredsSuccess,
  onCredsFailure,
  onPasswordExpired,
  onPasswordResetRequest,
  onRequestOtp,
  isSeamlessLoginFailed,
  wasSeamlessLoginShowedAndDenied,
  goBackToSeamlessLogin,
  credentialsLoginAttemptCounter,
  setCredentialsLoginAttemptCounter,
}: OtpLoginPageProps) => {
  return (
    <div className="otp-login">
      {wasSeamlessLoginShowedAndDenied && <BackButton onBack={goBackToSeamlessLogin} />}
      {isSeamlessLoginFailed || credentialsMetadata?.shouldShowCredentialsWithError ? (
        <SeamlessLoginFailed isMultipleLoginOptions />
      ) : null}
      <div>
        <h1 className="otp-login-title">Let’s get started</h1>
        <RequestOtpButton onRequestOtp={onRequestOtp} />
      </div>
      <div className="or-separator">
        <hr />
        <span className="or-text">Or</span>
      </div>
      <div className="margin-top-10">
        <CredentialsForm
          ehrCredentials={ehrCredentials}
          organizationId={organizationId}
          inputStyle="large"
          onSuccess={onCredsSuccess}
          onFailure={onCredsFailure}
          onPasswordExpired={onPasswordExpired}
          onPasswordResetRequest={onPasswordResetRequest}
          usernamePlaceholder={ehrUsername ?? 'Username'}
          usernameLabel="Username"
          passwordPlaceholder="Password"
          passwordLabel="Vim password"
          credentialsLoginAttemptCounter={credentialsLoginAttemptCounter}
          setCredentialsLoginAttemptCounter={setCredentialsLoginAttemptCounter}
        />
      </div>
    </div>
  );
};
OtpLoginPage.defaultProps = {
  isSeamlessLoginFailed: false,
  wasSeamlessLoginShowedAndDenied: false,
};
