import React, { PropsWithChildren } from 'react';
import { VimLogoBar, VimWidgetFooter } from '../../atoms';
import './index.less';

interface LoginWidgetWrapperProps {
  className?: string;
  onCloseClicked?: () => void;

  /** if wrapper should be hidden */
  hidden?: boolean;
}
export const LoginWidgetWrapper = ({
  children,
  className,
  onCloseClicked,
  hidden = false,
}: PropsWithChildren<LoginWidgetWrapperProps>) => {
  return (
    <div className="padding-box-20" hidden={hidden}>
      <div className={`login-wrapper ${className ?? ''}`}>
        <VimLogoBar onCloseClicked={onCloseClicked} />
        <div className="login-wrapper-children">{children}</div>
        <VimWidgetFooter />
      </div>
    </div>
  );
};
