import React from 'react';

interface SeamlessLoginFailedProps {
  isMultipleLoginOptions?: boolean;
}

export const SeamlessLoginFailed: React.FC<SeamlessLoginFailedProps> = ({
  isMultipleLoginOptions,
}) => {
  return (
    <div className="seamless-login-failure">
      <h1 style={{ marginBottom: 0 }}>Oops, </h1>
      <div className="text-14 subtitle">
        Looks like we had an issue,
        {isMultipleLoginOptions
          ? ' please choose one of the options'
          : ' please insert your email and password below'}
      </div>
    </div>
  );
};
