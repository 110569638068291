import React, { FC, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Button, InputStyle } from '@getvim/atomic-ui';
import { testRules } from './rules-logic';
import { PasswordRulesBox } from './password-rules-box';
import { Loader } from '../loader';
import LoginFormRow from '../login-form-row';
import { changePasswordMutation } from '../../logic/queries';
import { AccessToken, LoginResult, UserType } from '../../logic/types';
import { useLogin } from '../../hooks';
import { Credentials } from '../../types';

import '../../resources/styles/password-expired.less';
import { Team, useFeatureFlag } from '@getvim/feature-flags-react';

const GENERIC_ERROR_MSG = 'Could not change the password, please try again';

export interface PasswordExpiredFormProps {
  onSuccess(loginResult: LoginResult): void;
  vimCredentials: Credentials;
  ehrCredentials: Credentials;
  organizationId: number;
}

export const PasswordExpiredForm: FC<PasswordExpiredFormProps> = ({
  onSuccess,
  vimCredentials,
  ehrCredentials,
  organizationId,
}) => {
  const [password, setPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [accessToken, setAccessToken] = useState<AccessToken>();
  const [changePasswordErrorMessage, setChangePasswordErrorMessage] = useState<string>();
  const [loginAppShouldResetPasswordRuntimeApi] = useFeatureFlag({
    flagName: 'loginAppShouldResetPasswordRuntimeApi',
    defaultValue: false,
    team: Team.OMT,
  });
  const [changePasswordRequest, { loading, error }] = useMutation(changePasswordMutation, {
    errorPolicy: 'all',
    context: {
      uri: loginAppShouldResetPasswordRuntimeApi ? '/runtime/api/graphql' : '/api/graphql',
    },
  });
  const { login, loading: loginLoading } = useLogin(organizationId);

  const changePassword = async () => {
    try {
      const result = await changePasswordRequest({
        variables: {
          input: {
            vimCredentials,
            newPassword: password,
            userType: UserType.VimEhrUser,
            organizationId,
          },
        },
      });
      const { success, errorMessage } = result.data.changePassword ?? {};

      if (!success) {
        setChangePasswordErrorMessage(errorMessage);
        return;
      }
      const ehrCreds = { username: ehrCredentials.username, password: ehrCredentials.password };
      const loginResponse = await login({
        organizationId,
        vimCredentials: { ...vimCredentials, password },
        ehrCredentials: ehrCreds,
      });
      setAccessToken(loginResponse);
    } catch (err: any) {
      setChangePasswordErrorMessage(GENERIC_ERROR_MSG);
    }
  };

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    onSuccess({ accessToken } as LoginResult);
  }, [, onSuccess, accessToken]);

  if (loading || loginLoading) {
    return <Loader />;
  }

  const isValidPassword = testRules(password);
  const passwordAndConfirmationMatched = password === confirmationPassword;
  const canSubmit = isValidPassword && passwordAndConfirmationMatched;

  return (
    <div>
      <h1 className="margin-bottom-15">Your password has expired, please set a new password</h1>
      <form name="vim-ehr-login" autoComplete="off">
        <LoginFormRow
          inputStyle={InputStyle.large}
          type="password"
          id="password"
          placeholder="New Password"
          value={password}
          onChanged={(value) => setPassword(value)}
        />
        <LoginFormRow
          inputStyle={InputStyle.large}
          id="confirmation-password"
          placeholder="Re-enter new password"
          type="password"
          value={confirmationPassword}
          onChanged={(value) => setConfirmationPassword(value)}
        />

        <div className="match-validation-message">
          {(changePasswordErrorMessage || error) && (
            <span>• {changePasswordErrorMessage || GENERIC_ERROR_MSG}</span>
          )}

          {!passwordAndConfirmationMatched && confirmationPassword && (
            <span>• Password and confirmation unmatched</span>
          )}
        </div>

        <div className="text-center margin-bottom">
          <Button className="btn-blue" onClick={changePassword} disabled={!canSubmit}>
            Confirm
          </Button>
        </div>
        {!isValidPassword && password && (
          <div className="password-rules-wrap text-center margin-top-30">
            <PasswordRulesBox password={password} />
            <div className="password-strength-error">
              <i className="icon-warning" />
              <div className="password-strength-error-text">Please choose a stronger password</div>
              <i className="icon-chevron-up" />
            </div>
          </div>
        )}
      </form>
    </div>
  );
};
