import { MovementDirection } from '../../../atomic/molecules/inlineLoginProgressWidget';
import { LoginWidgetModificationPayload } from '../../../logic/messaging';

export enum WidgetPosition {
  Low,
  Middle,
  High,
}

export const HEIGHT_MARGIN_PIXELS = 10;
export const WRAPPER_HEIGHT_VH = 90;
export const MENU_WIDTH_PIXELS = 50;
export const MENU_WIDTH_MARGIN = 10;
export const MENU_WRAPPER_TOP_MARGIN = 70;
export const APP_BUTTON_EDGE_LENGTH_PIXELS = 50;
const CHEVRON_BUTTON_HEIGHT_PIXELS = 20;
const HIGH_POSITION_TOP = 110;
const MID_POSITION_TOP = 270;
const LOW_POSITION_TOP = 515;

const lengthToPixelString = (length: number): string => `${length}px`;

const positionToTop: Record<WidgetPosition, number> = {
  [WidgetPosition.High]: HIGH_POSITION_TOP,
  [WidgetPosition.Middle]: MID_POSITION_TOP,
  [WidgetPosition.Low]: LOW_POSITION_TOP,
};

export const movementToPosition = (
  position: WidgetPosition,
  direction: MovementDirection,
): number => {
  if (
    (position === WidgetPosition.High && direction === MovementDirection.Up) ||
    (position === WidgetPosition.Low && direction === MovementDirection.Down)
  ) {
    return position;
  }
  return direction === MovementDirection.Up ? position + 1 : position - 1;
};

export const getPosition = ({
  position,
}: {
  position: WidgetPosition;
}): LoginWidgetModificationPayload => {
  const width = APP_BUTTON_EDGE_LENGTH_PIXELS + MENU_WIDTH_MARGIN;
  let height = APP_BUTTON_EDGE_LENGTH_PIXELS;
  const top = `${positionToTop[position]}`;
  const right = '0';

  height += CHEVRON_BUTTON_HEIGHT_PIXELS * 2 + HEIGHT_MARGIN_PIXELS * 2;
  return {
    size: { width: lengthToPixelString(width), height: lengthToPixelString(height) },
    alignment: { top, right },
  };
};
