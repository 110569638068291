import React, { ReactNode } from 'react';
import { InputValidationMessage, InputStyleWrapper, InputStyle } from '@getvim/atomic-ui';
import { LoginFormLikeInputDiv } from './login-form-like-input-div/login-form-like-input-div';

export interface LoginFormRowProps {
  value: string;
  onChanged: (value: string) => void;
  type: string;
  placeholder: string;
  id: string;
  error?: string;
  labelText?: string;
  inputStyle: InputStyle;
  disabled?: boolean;
  rightIcon?: ReactNode;
  immutable?: boolean;
}

const LoginFormRow: React.FC<LoginFormRowProps> = ({
  value,
  onChanged,
  id,
  placeholder,
  type,
  error,
  labelText,
  inputStyle,
  disabled,
  rightIcon,
  immutable,
}: LoginFormRowProps) => {
  return (
    <div className={`login-form-row ${error && 'has-error'}`}>
      <InputStyleWrapper
        inputStyle={inputStyle}
        rightIcon={rightIcon ?? undefined}
        className={disabled ? 'disabled' : ''}
      >
        {labelText ? <label htmlFor={id}>{labelText}</label> : undefined}
        <div className="input">
          {immutable ? (
            <LoginFormLikeInputDiv value={value} id={id} />
          ) : (
            <input
              type={type}
              id={id}
              placeholder={placeholder}
              value={value}
              onChange={(e) => onChanged(e.target.value)}
            />
          )}
        </div>
        <InputValidationMessage>{error}</InputValidationMessage>
      </InputStyleWrapper>
    </div>
  );
};

export default LoginFormRow;
