import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, InputStyle, Tooltip } from '@getvim/atomic-ui';
import { Login } from '@getvim/vim-connect';
import { Lock } from '@material-ui/icons';
import { useLogin } from '../hooks';
import { AccessToken, LoginResult } from '../logic/types';
import { Credentials } from '../types';
import { Loader } from './loader';
import LoginFormRow from './login-form-row';
import { PasswordResetLink } from './password-reset/password-reset-link';
import { analyticsClient, VimConnectCredentialsLoginAnalyticsEventTypes } from '../logic/analytics';
import { sendUserLoginAttempt } from '../logic/messaging';

const ERROR_MSG = "Hey, let's try that again, please check your email and password.";
const MAX_ERROR_COUNT = 3;

interface LoginButtonProps {
  onLogin: () => void;
  disabled: boolean;
}

const LoginButton: React.FC<LoginButtonProps> = ({ onLogin, disabled }: LoginButtonProps) => (
  <div className="text-center margin-top-20">
    <Button
      className="vim-connect-login-btn"
      bgColor="themedOutline"
      type="submit"
      onClick={onLogin}
      disabled={disabled}
    >
      Login
    </Button>
  </div>
);

interface CredentialsFormProps {
  inputStyle: keyof typeof InputStyle;
  ehrCredentials?: Credentials;
  organizationId: number;
  usernameLabel?: string;
  usernamePlaceholder: string;
  passwordLabel?: string;
  passwordPlaceholder: string;
  shouldShowTitle?: boolean;
  credentialsLoginAttemptCounter: number;
  setCredentialsLoginAttemptCounter: Dispatch<SetStateAction<number>>;

  onSuccess(loginResult: LoginResult): void;

  onFailure(error: Error): void;

  onPasswordResetRequest(): void;

  onPasswordExpired(vimCredentials: Credentials): void;
}

const CredentialsForm: React.FC<CredentialsFormProps> = ({
  ehrCredentials,
  organizationId,
  onSuccess,
  onFailure,
  onPasswordResetRequest,
  onPasswordExpired,
  usernameLabel,
  usernamePlaceholder,
  passwordLabel,
  passwordPlaceholder,
  inputStyle,
  shouldShowTitle,
  credentialsLoginAttemptCounter,
  setCredentialsLoginAttemptCounter,
}: CredentialsFormProps) => {
  const [username, setUsername] = useState(usernamePlaceholder);
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [accessToken, setAccessToken] = useState<AccessToken>();
  const [hasError, setHasError] = useState(credentialsLoginAttemptCounter > 0);

  const { login, loading: loginLoading } = useLogin(organizationId);

  const loading = isLoading || loginLoading;
  let isSuccessfulLogin;

  const onLoginError = (errorCount: number) => {
    if (errorCount >= MAX_ERROR_COUNT) {
      onFailure(new Error(`User login has failed ${MAX_ERROR_COUNT} times.`));
    }
    setHasError(errorCount > 0);
  };

  const onLogin = async () => {
    if (!ehrCredentials) return;
    const attemptCounter = credentialsLoginAttemptCounter + 1;

    setIsLoading(true);
    try {
      const loginResponse = await login({
        organizationId,
        vimCredentials: { username, password },
        ehrCredentials: { username: ehrCredentials.username, password: ehrCredentials.password },
      });
      sendUserLoginAttempt(Login.LoginMethodType.CREDENTIALS, {
        accessToken: loginResponse.accessToken,
      });
      setAccessToken(loginResponse);
      isSuccessfulLogin = true;
    } catch (error: any) {
      isSuccessfulLogin = false;
      sendUserLoginAttempt(Login.LoginMethodType.CREDENTIALS, {
        errorMessage: error?.message,
        attemptNumber: attemptCounter,
      });

      if (error?.message === 'Password has expired') onPasswordExpired({ username, password });
      if (error?.message === Login.INTERNAL_SERVER_ERROR_MESSAGE) onFailure(error);
      onLoginError(attemptCounter);
    } finally {
      setIsLoading(false);
      analyticsClient.track(
        VimConnectCredentialsLoginAnalyticsEventTypes.VimConnectCredentialsLoginButtonClicked,
        { is_success: isSuccessfulLogin, attempt_number: attemptCounter },
      );
      setCredentialsLoginAttemptCounter(attemptCounter);
    }
  };

  useEffect(() => {
    if (!accessToken) return;
    onSuccess({ accessToken } as LoginResult);
  }, [accessToken, onSuccess]);

  if (loading) return <Loader />;

  return (
    <>
      {/* This is a temporary fix because this is used by both regular and otp login */}
      {shouldShowTitle && <h1 className="login-title">Let’s get started</h1>}
      <form name="vim-login" onSubmit={onLogin} autoComplete="off">
        <Tooltip
          tooltipContent="This is your EHR Username"
          referenceClassName="block"
          placement="top"
        >
          <LoginFormRow
            inputStyle={InputStyle[inputStyle]}
            value={username}
            onChanged={setUsername}
            type="text"
            id="username"
            placeholder={usernamePlaceholder}
            labelText={usernameLabel}
            disabled
            rightIcon={<Lock />}
            immutable
          />
        </Tooltip>

        <LoginFormRow
          inputStyle={InputStyle[inputStyle]}
          value={password}
          onChanged={setPassword}
          type="password"
          id="password"
          placeholder={passwordPlaceholder}
          error={hasError ? ERROR_MSG : undefined}
          labelText={passwordLabel}
        />
        <PasswordResetLink onClick={onPasswordResetRequest} />
        <LoginButton onLogin={onLogin} disabled={!(username && password)} />
      </form>
    </>
  );
};

CredentialsForm.defaultProps = {
  ehrCredentials: undefined,
  usernameLabel: undefined,
  passwordLabel: undefined,
  shouldShowTitle: false,
};

export default CredentialsForm;
