import { CredentialsMetadata, EhrCredentials } from './credentials';
import { ContactDetail } from './contact-details';
import { SeamlessLoginConfig } from './seamlessLogin';

export enum Event {
  CREDENTIALS = 'vim-ehr-login-credentials',
  DISPLAY_RE_LOGIN_SCREEN = 'vim-ehr-display-re-login-screen',
  INIT_ORGANIZATION_ID = 'vim-ehr-init-organization-id',
  SET_OTP_CONTACT_DETAILS = 'set-otp-contact-details',
  SEAMLESS_LOGIN_FAILED = 'vim-ehr-login-seamless-failed',
  SEAMLESS_LOGIN_INIT = 'vim-ehr-login-seamless-init',
  SEAMLESS_LOGIN_SUCCESS = 'vim-ehr-login-seamless-success',
}
export interface LoginEvents {
  ehrCredentials?: EhrCredentials;
  credentialsMetadata: CredentialsMetadata | undefined;
  reLoginConfig?: ReLoginConfig;
  organizationId: number;
  contactDetails: ContactDetail[];
  isSeamlessLoginFailed?: boolean;
  seamlessLoginConfiguration?: SeamlessLoginConfig | undefined;
}

export interface ReLoginConfig {
  message: string;
  title?: string;
  showLockImage?: boolean;
}
