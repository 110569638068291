import React, { useCallback, useState } from 'react';
import { AlignEnum, Button, ColorNameEnum, SizeEnum, Text, WeightEnum } from '@getvim/atomic-ui';
import { LoginWidgetWrapper } from '../../templates/login-widget-wrapper';
import lockSvg from '../../../resources/images/lock.svg';
import './index.less';
import { SeamlessConsentInfo } from '../../molecules/seamless-consent-info';

export interface ApprovalWidgetProps {
  onApproved: () => void;
  onDenied: () => void;
  onClose: () => void;
  onLearnMoreVisibilityChanged: (visible: boolean) => void;
  showDifferentMethods: boolean;
}

export const ApprovalWidget: React.FC<ApprovalWidgetProps> = ({
  onApproved,
  onDenied,
  onClose,
  onLearnMoreVisibilityChanged,
  showDifferentMethods,
}) => {
  const [learnMoreVisible, setLearnMoreVisibility] = useState(false);
  const showLearnMore = useCallback(() => {
    setLearnMoreVisibility(true);
    onLearnMoreVisibilityChanged(true);
  }, [onLearnMoreVisibilityChanged, setLearnMoreVisibility]);
  const hideLearnMore = useCallback(() => {
    setLearnMoreVisibility(false);
    onLearnMoreVisibilityChanged(false);
  }, [onLearnMoreVisibilityChanged, setLearnMoreVisibility]);

  return (
    <LoginWidgetWrapper className="approval-widget" onCloseClicked={onClose}>
      {learnMoreVisible ? (
        <SeamlessConsentInfo goBack={hideLearnMore} />
      ) : (
        <>
          <Text
            className="consent-title"
            size={SizeEnum['22px']}
            weight={WeightEnum.semibold}
            align={AlignEnum.left}
            colorName={ColorNameEnum.theme}
          >
            Welcome to Vim!
          </Text>

          <div className="consent-details">
            <Text size={SizeEnum['14px']} colorName={ColorNameEnum.theme} align={AlignEnum.left}>
              When you login into the Electronic Health Record System (<b>EHR</b>) the EHR issues a
              unique certificate that matches with your username.
            </Text>
            <Text size={SizeEnum['14px']} colorName={ColorNameEnum.theme} align={AlignEnum.left}>
              Vim will gain access to this certificate to verify your identity.
            </Text>
            <Text size={SizeEnum['14px']} colorName={ColorNameEnum.theme} align={AlignEnum.left}>
              This action will happen only once, Vim doesn’t save your certificate anywhere.
            </Text>
          </div>
          <img src={lockSvg} alt="lock" className="lock-img" />
          <Button className="learn-more" buttonType="link" color="gray" onClick={showLearnMore}>
            <Text size={SizeEnum['14px']} underline>
              Learn more
            </Text>
          </Button>

          <div className="approval-actions">
            <Button buttonType="small" width="block" onClick={onApproved}>
              <Text size={SizeEnum['18px']}>Allow</Text>
            </Button>
            {showDifferentMethods && (
              <>
                <div className="or-separator">
                  <span className="divider" />
                  <Text size={SizeEnum['14px']}>Or</Text>
                  <span className="divider" />
                </div>
                <Button
                  className="deny-button"
                  buttonType="outline"
                  width="block"
                  bgColor="white"
                  onClick={onDenied}
                >
                  <Text size={SizeEnum['18px']} weight={WeightEnum.semibold}>
                    Choose a different login method
                  </Text>
                </Button>
              </>
            )}
          </div>
        </>
      )}
    </LoginWidgetWrapper>
  );
};
