import React from 'react';
import { wrapper } from './styles.module.less';

export const LoginFormLikeInputDiv = ({ value, id }) => {
  return (
    <div className={wrapper} id={id}>
      <p>{value}</p>
    </div>
  );
};
