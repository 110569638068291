import React, { useState, useCallback, useEffect } from 'react';
import { getPosition, movementToPosition, WidgetPosition } from './utils/positioningUtils';
import { sendModifyLoginWidget } from '../../logic/messaging';
import { Molecules } from '../../atomic';
import { useInlineWidgetContext } from './InlineWidgetContext';
import { analyticsClient, VimConnectSeamlessAnalyticsEventTypes } from '../../logic/analytics';
import { MovementDirection } from '../../atomic/molecules';

interface SeamlessLoginInlineDisplayProps {
  onWidgetClick: () => void;
  loginPercentage: number;
}

export const SeamlessLoginInlineDisplay: React.FC<SeamlessLoginInlineDisplayProps> = (props) => {
  const {
    context: { direction, modification },
    updateContext,
  } = useInlineWidgetContext();
  const [widgetPosition, setWidgetPosition] = useState<WidgetPosition>(direction);

  useEffect(() => {
    const newPosition = getPosition({ position: widgetPosition });
    const oldPosition = modification;
    updateContext({
      modification: { ...modification, ...newPosition },
      direction: widgetPosition,
    });
    sendModifyLoginWidget(newPosition);
    if (JSON.stringify(newPosition) !== JSON.stringify(oldPosition)) {
      analyticsClient.track(
        VimConnectSeamlessAnalyticsEventTypes.VimConnectSeamlessPositionChange,
        {
          direction: widgetPosition,
          new_position: newPosition,
          old_position: oldPosition,
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateContext, widgetPosition]);

  const moveWidget = useCallback(
    (newDirection: MovementDirection) => {
      const newPosition = movementToPosition(widgetPosition, newDirection);
      if (widgetPosition !== newPosition) {
        setWidgetPosition(newPosition);
      }
    },
    [widgetPosition],
  );

  return (
    <Molecules.InlineLoginProgressWidget
      {...props}
      moveWidget={moveWidget}
      topButtonDisabled={widgetPosition === WidgetPosition.High}
      bottomButtonDisabled={widgetPosition === WidgetPosition.Low}
    />
  );
};
