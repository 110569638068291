import React from 'react';
import { BrandedH1, Button } from '@getvim/atomic-ui';

export const UserWithEmail = ({ email, username, onPasswordResetSubmit }) => {
  return (
    <div className="user-with-email">
      <BrandedH1 margin="none" align="left" text="Reset Password" />
      <p className="ask-to-reset">
        <b>Hey {username},</b> <br />
        Do you want to reset your password? Just tap the button below.
      </p>
      <div className="call-to-reset-password">
        <Button className="btn-blue" onClick={onPasswordResetSubmit}>
          <p>Send to email {email}</p>
        </Button>
      </div>
      <div className="help-text-footer text-center">
        <p>
          If the email is incorrect you can contact your IT manager or send us an email to
          provider@getvim.com
        </p>
      </div>
    </div>
  );
};
