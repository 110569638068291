import React from 'react';
import { Loader, Button } from '@getvim/atomic-ui';
import { BackButton } from './back-button';
import { ContactDetailType } from '../../types';

interface RequestOtpProps {
  userEmail?: string;
  userPhoneNumber?: string;
  onBack: () => void;
  onSendOtpRequest: (contactDetailType: ContactDetailType) => void;
  isLoading: boolean;
}

export const RequestOtpPage = ({
  userEmail,
  userPhoneNumber,
  onBack,
  onSendOtpRequest,
  isLoading,
}: RequestOtpProps) => {
  const onSendToEmail = async () => {
    onSendOtpRequest(ContactDetailType.email);
  };

  const onSendToPhone = async () => {
    onSendOtpRequest(ContactDetailType.phone);
  };

  const hasBothMethods = userEmail && userPhoneNumber;
  return (
    <div className="otp-request otp-login">
      <BackButton onBack={onBack} />
      <h1 className="margin-bottom-20">One Time Password</h1>
      <p className="explanation">
        Easily get a code to your {userEmail && 'email'} {hasBothMethods && 'or '}
        {userPhoneNumber && 'phone'} and start using Vim without further delay.
      </p>
      <div className="call-to-action">
        {hasBothMethods && <b>Please choose your preferred way to get the code</b>}
        {userEmail && (
          <Button className="btn-blue" onClick={onSendToEmail} disabled={isLoading}>
            {isLoading ? (
              <Loader type="dots" size="small" padding="none" color="white" />
            ) : (
              <p>Send to email {userEmail}</p>
            )}
          </Button>
        )}
        {userPhoneNumber && (
          <Button className="btn-blue" onClick={onSendToPhone} disabled={isLoading}>
            {isLoading ? (
              <Loader type="dots" size="small" padding="none" color="white" />
            ) : (
              <p>Send to phone {userPhoneNumber}</p>
            )}
          </Button>
        )}
      </div>
      <div className="disclaimer-footer">
        <p>
          If the email or phone number is not correct you can contact your IT manager or send us an
          email to provider@getvim.com
        </p>
      </div>
    </div>
  );
};

RequestOtpPage.defaultProps = {
  userEmail: undefined,
  userPhoneNumber: undefined,
};
