import React from 'react';
import footerImg from '../../../resources/images/footer-image.png';
import './index.less';

export function VimWidgetFooter() {
  return (
    <div className="vim-widget-footer">
      <img src={footerImg} className="bg-img" alt="background" />
    </div>
  );
}
