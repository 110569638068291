import React from 'react';
import errorImage from '../resources/images/error-image.png';

export const FailureScreen: React.FC = () => (
  <div className="padding-h-20 text-center padding-top-40">
    <img alt="error" src={errorImage} className="error-img" />
    <h1>There was a problem logging in</h1>
    <div className="subtitle text-14">Please contact your account admin</div>
  </div>
);
