import React, { useCallback } from 'react';
import { ProgressBar } from '@getvim/atomic-ui';
import hourglassSvg from '../../resources/images/hourglass.svg';
import {
  EhrCredentials,
  OnFailureCallback,
  OnSuccessCallback,
  SeamlessCredentials,
} from '../../types';
import { SeamlessLoginSuccess } from './SeamlessLoginSuccess';
import { sendFailedSeamless } from '../../logic/messaging';

interface SeamlessLoginExtendedDisplayProps {
  isSeamlessSucceeded?: boolean;
  ehrCredentials?: EhrCredentials;
  seamlessCredentials?: SeamlessCredentials;
  organizationId: number;
  loginPercentage: number;
  onSuccess: OnSuccessCallback;
}

export const SeamlessLoginExtendedDisplay: React.FC<SeamlessLoginExtendedDisplayProps> = ({
  isSeamlessSucceeded,
  ehrCredentials,
  organizationId,
  seamlessCredentials,
  loginPercentage,
  onSuccess,
}) => {
  const handleFailure: OnFailureCallback = useCallback((error, shouldRetry) => {
    sendFailedSeamless(error, shouldRetry);
  }, []);

  return isSeamlessSucceeded ? (
    <SeamlessLoginSuccess
      ehrCredentials={ehrCredentials}
      organizationId={organizationId}
      seamlessCredentials={seamlessCredentials}
      onSuccess={onSuccess}
      onFailure={handleFailure}
    />
  ) : (
    <div className="padding-h-10">
      <h1 className="seamless-setup-title">Vim is setting up</h1>
      <div className="text-14 subtitle">This process will take a few moments</div>
      <div className="text-center">
        <img src={hourglassSvg} alt="hourglass" className="hourglass-img-expanded" />
      </div>
      <ProgressBar
        className="seamless-login-progress-bar-expanded"
        showLabel
        progressBarStyle="thin"
        value={loginPercentage}
      />
    </div>
  );
};
