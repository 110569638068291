import React from 'react';
import vimLogo from '../../../resources/logos/vimLogoMd.png';
import './index.less';

export interface VimLogoBarProps {
  onCloseClicked?: () => void;
}

export function VimLogoBar({ onCloseClicked }: VimLogoBarProps) {
  return (
    <header className="vim-logo-bar">
      <img src={vimLogo} className="vim-logo" alt="vim logo" />
      {onCloseClicked && (
        <button className="close-icon" type="button" onClick={onCloseClicked}>
          <i className="icon-x" />
        </button>
      )}
    </header>
  );
}
