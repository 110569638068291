import React from 'react';
import { Button } from '@getvim/atomic-ui';
import SpaceshipSbg from '../../resources/images/spaceship.svg';

interface ErrorPageProps {
  onGoBack: () => void;
}

export const ErrorPage = ({ onGoBack }: ErrorPageProps) => (
  <div className="error-page-login">
    <h1>There was a problem logging in</h1>
    <div className="error-image">
      <img src={SpaceshipSbg} alt="spaceship" />
      <Button buttonType="link" className="link-btn restart-btn" onClick={onGoBack}>
        Go back to start
      </Button>
    </div>
  </div>
);
