import React from 'react';
import { Button, Text } from '@getvim/atomic-ui';
import './index.less';

export interface BackBtnProps {
  onBack(): void;
}

export const BackButton = ({ onBack }: BackBtnProps) => (
  <Button buttonType="link" onClick={onBack} className="widget-back-btn">
    <i className="icon-chevron-2" />
    <Text>Back</Text>
  </Button>
);
