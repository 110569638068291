import React from 'react';
import ReactDOM from 'react-dom';
import { WidgetsMessageChannel } from '@getvim/vim-connect';
import 'bootstrap-less/bootstrap/bootstrap.less';
import '@getvim/atomic-ui/assets/styles/main.less';
import { ApolloProvider } from '@apollo/client';
import Login from './components/Login';
import gqlClient from './logic/gql-client';

WidgetsMessageChannel.setupParentPorts().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <ApolloProvider client={gqlClient}>
        <Login />
      </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
});
